<template>
  <v-container fluid>
    <base-material-card icon="mdi-account-multiple" title="Cliente" class="px-5 py-3">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-text-field label="Razón social" v-model="cliente.razon_social" :error-messages="errors.razon_social"
                            class="required"></v-text-field>
              <v-text-field label="RFC" v-model="cliente.rfc" :error-messages="errors.rfc"
                            @keyup="loadRegimenDebounce"
                            class="required"></v-text-field>
              <v-select label="Regimen Fiscal"
                v-model="cliente.regimen_fiscal"
                        :items="regimenes_fiscales"
                        item-value="regimen_fiscal"
                        :item-text="(item)=>`${item.regimen_fiscal} - ${item.descripcion}`"
                        :error-messages="errors.regimen_fiscal"
                        class="required"></v-select>
              <v-select v-model="cliente.categoria_id" :items="categorias" label="Categoría" item-value="id"
                        item-text="nombre" :error-messages="errors.categoria_id" class="required"></v-select>
              <v-select v-model="cliente.emisor_id" :items="emisores" label="Emisor" item-value="id"
                        item-text="razon_social" :error-messages="errors.emisor_id" class="required"></v-select>
              <v-select v-model="cliente.moneda" :items="monedas" label="Monedas" :error-messages="errors.moneda"
                        class="required"></v-select>
              <v-text-field label="Porcentaje de descuento" v-model="cliente.porcentaje_descuento"
                            :error-messages="errors.porcentaje_descuento" class="required"></v-text-field>
              <v-text-field label="Límite de crédito" v-model="cliente.credito_limite"
                            :error-messages="errors.credito_limite" type="number" class="required"></v-text-field>
              <v-text-field label="Días de crédito" v-model="cliente.credito_dias" :error-messages="errors.credito_dias"
                            type="number" class="required"></v-text-field>
              <v-text-field label="Porcentaje de arriesgue" v-model="cliente.porcentaje_arriesgue"
                            :error-messages="errors.porcentaje_arriesgue" :suffix="`${!isNaN(cliente.porcentaje_arriesgue)?cliente.porcentaje_arriesgue*100:''}%`"
                            class="required"></v-text-field>
              <v-text-field label="Email" v-model="email" :error-messages="errors.email" @keyup.enter="addEmail"
                            append-outer-icon="fa fa-plus" @click:append-outer="addEmail"
                            class="required"></v-text-field>
              <v-list subheader>
                <v-list-item v-for="(item, i) in cliente.email" :key="item">
                  <v-list-item-icon>
                    <v-icon>far fa-envelope</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="selectDefaultEmail(i)">
                      <v-icon v-if="i == 0">fa fa-star</v-icon>
                      <v-icon v-if="i >  0">far fa-star</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon @click="removeEmail(i)">
                      <v-icon>far fa-trash-alt</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-text-field label="Teléfono" type="number" v-model="telefono" :error-messages="errors.telefono"
                            @keyup.enter="addTelefono" append-outer-icon="fa fa-plus" @click:append-outer="addTelefono"
                            class="required"></v-text-field>
              <v-list subheader>
                <v-list-item v-for="(item, i) in cliente.telefono" :key="item">
                  <v-list-item-icon>
                    <v-icon>far fa-address-book</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon @click="selectDefaultTelefono(i)">
                      <v-icon v-if="i == 0">fa fa-star</v-icon>
                      <v-icon v-if="i >  0">far fa-star</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon @click="removeTelefono(i)">
                      <v-icon>far fa-trash-alt</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-textarea label="Observaciones" v-model="cliente.observaciones"
                          :error-messages="errors.observaciones"></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Calle" v-model="cliente.calle" :error-messages="errors.calle">
              </v-text-field>
              <v-text-field label="Núm. Exterior" v-model="cliente.num_exterior"
                            :error-messages="errors.num_exterior"></v-text-field>
              <v-text-field label="Núm. Interior" v-model="cliente.num_interior"
                            :error-messages="errors.num_interior"></v-text-field>
              <v-text-field label="Colonia" v-model="cliente.colonia" :error-messages="errors.colonia">
              </v-text-field>
              <v-text-field label="Codigo Postal" v-model="cliente.codigo_postal_domicilio"
                            :error-messages="errors.codigo_postal_domicilio" class="required">
              </v-text-field>
              <v-text-field label="Localidad" v-model="cliente.localidad"
                            :error-messages="errors.localidad"></v-text-field>
              <v-text-field label="Municipio" v-model="cliente.municipio"
                            :error-messages="errors.municipio"></v-text-field>
              <v-select v-model="cliente.estado_id" :items="estados" label="Estado" item-value="id" item-text="nombre"
                        :error-messages="errors.estado_id"></v-select>
            </v-col>
            <v-col cols="12">
              <v-file-input label="Adjuntos" v-model="adjunto" :error-messages="errors.adjuntos_files"
                            @keyup.enter="addAdjunto" append-outer-icon="fa fa-plus"
                            @click:append-outer="addAdjunto"></v-file-input>
              <v-list subheader>
                <v-list-item v-for="item in cliente.adjuntos_files" :key="item.name">
                  <v-list-item-icon>
                    <v-icon>far fa-envelope</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                      <v-btn icon @click="">
                          <v-icon color="grey lighten-1">mdi-information</v-icon>
                      </v-btn>
                  </v-list-item-action> -->
                </v-list-item>
                <v-list-item v-for="item in cliente.adjuntos" :key="item.name" @click="showFile(item)">
                  <v-list-item-icon>
                    <v-icon>far fa-envelope</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                      <v-btn icon @click="">
                          <v-icon color="grey lighten-1">mdi-information</v-icon>
                      </v-btn>
                  </v-list-item-action> -->
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-switch v-model="cliente.sucursal_unica" label="Sucursal única" value="1"
                        @change="loadListas(); loadVendedores()"></v-switch>
            </v-col>
            <v-col cols="6" v-show="cliente.sucursal_unica">
              <v-divider></v-divider>
              <v-subheader>Datos de la sucursal</v-subheader>
              <v-text-field v-model="cliente.nombre" label="Nombre" :error-messages="errors.nombre"
                            class="required"></v-text-field>
              <v-select v-model="cliente.lista_id" :items="listas" label="Listas de precio" item-value="id"
                        item-text="nombre" :error-messages="errors.lista_id" class="required"></v-select>
              <v-select v-model="cliente.vendedor_id" :items="vendedores" label="Vendedor" item-value="id"
                        item-text="razon_social" :error-messages="errors.vendedor_id" class="required"></v-select>
              <v-text-field v-model="cliente.encargado" label="Encargado" :error-messages="errors.encargado"
                            class="required"></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="save" :loading="loading">Guardar</v-btn>
          <v-btn @click="$router.push({name: 'Cliente'})" :loading="loading">Cancelar</v-btn>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
import _ from 'lodash'
export default {
  data: () => ({
    categorias: [],
    emisores: [],
    listas: [],
    vendedores: [],
    estados: [],
    regimenes_fiscales:[],
    monedas: [
      'MXN', 'USD'
    ],
    email_headers: [
      {text: 'Email', value: '', sortable: false},
      {text: 'Acciones', value: 'accciones', sortable: false},
    ],
    telefono_headers: [
      {text: 'Telefono', value: '', sortable: false},
      {text: 'Acciones', value: 'accciones', sortable: false},
    ],
    email: null,
    telefono: null,
    adjunto: null,
    cliente: {
      razon_social:'',
      rfc:'',
      porcentaje_arriesgue: '',
      email: [],
      telefono: [],
      adjuntos_files: [],
      regimen_fiscal: '',
      codigo_postal_domicilio: '',
    },
    errors: {},
    loading: false
  }),
  mounted() {
    this.loadCategorias()
    this.loadEmisores()
    this.loadEstados()
    if (this.$route.params.id) {
      this.load()
    }else{
      this.loadRegimenesFiscales()
    }
  },
  methods: {
    loadRegimenDebounce: _.debounce(function () {
      const rfcLenght = [11,12,13];
      if(rfcLenght.includes(this.cliente.rfc.length)){
        this.loadRegimenesFiscales();
      }
    }, 500),
    loadRegimenesFiscales() {
      const {rfc} = this.cliente;
      this.$http.get('/regimenes_fiscales', {
        params:{
          rfc
        }
      }).then(response => {
        this.regimenes_fiscales = response.data
      })
    },
    loadEmisores() {
      this.$http.get('/emisores/all').then(response => {
        this.emisores = response.data
      })
    },
    loadCategorias() {
      this.$http.get('/clientes/categorias/all').then(response => {
        this.categorias = response.data
      })
    },
    loadEstados() {
      this.$http.get('/estados/all').then(response => {
        this.estados = response.data
      })
    },
    load() {
      this.$http.get(`/clientes/${this.$route.params.id}`).then(response => {
        this.cliente = response.data
        this.cliente.adjuntos_files = []
        this.loadRegimenesFiscales();
      })
    },
    save() {
      this.errors = {}
      this.loading = true
      if (this.cliente.id) {
        this.update()
        return
      }
      this.create()
    },
    create() {
      this.$http.post('/clientes', this.cliente).then(response => {
        this.$router.push({name: 'Cliente'})
      }).catch(error => {
        console.log(error)
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors
            break;

          default:
            break;
        }
      }).finally(() => {
        this.loading = false
      })
    },
    update() {
      this.$http.put(`/clientes/${this.cliente.id}`, this.cliente).then(response => {
        this.$router.push({name: 'Cliente'})
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors
            break;

          default:
            break;
        }
      }).finally(() => {
        this.loading = false
      })
    },
    addEmail() {
      if (this.email) {
        this.cliente.email.push(this.email)
        this.email = null
      }
    },
    removeEmail(index) {
      this.cliente.email.splice(index, 1)
    },
    selectDefaultEmail(i) {
      let primary = this.cliente.email[i]

      this.cliente.email.splice(i, 1)
      this.cliente.email.unshift(primary)
    },
    addTelefono() {
      if (this.telefono) {
        this.cliente.telefono.push(this.telefono)
        this.telefono = null
      }
    },
    removeTelefono(index) {
      this.cliente.telefono.splice(index, 1)
    },
    selectDefaultTelefono(i) {
      let primary = this.cliente.telefono[i]

      this.cliente.telefono.splice(i, 1)
      this.cliente.telefono.unshift(primary)
    },
    addAdjunto() {
      if (this.adjunto) {
        this.cliente.adjuntos_files.push(this.adjunto)
        this.adjunto = null
      }
    },
    showFile(item) {
      if (item.url) {
        window.open(item.url)
      }
    },
    toFormData(object, method) {
      let formData = new FormData

      if (method) {
        formData.append('_method', method)
      }

      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          const element = object[key];
        }
        if (object[key]) {
          if (Array.isArray(object[key])) {
            object[key].forEach(subobject => {
              formData.append(`${key}[]`, subobject)
            })
          } else {
            formData.append(key, object[key])
          }
        }
      }
      return formData
    },
    loadListas() {
      if (this.listas.length) {
        return
      }
      this.$http.get('/listas/all').then(response => {
        this.listas = response.data
      })
    },
    loadVendedores() {
      if (this.vendedores.length) {
        return
      }
      this.$http.get('/vendedores/all').then(response => {
        this.vendedores = response.data
      })
    }
  }
}
</script>
